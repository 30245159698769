import React from "react"
import PropTypes from "prop-types"

const ResultCard = ({ item, isFirstItem }) => {
  const { trafficNumber, unit, title } = item

  return (
    <div className="flex justify-center items-center">
      <div
        className={`hidden mx-auto border-t border-white -translate-x-4 sm:mx-0 sm:border-r sm:border-t-0 sm:h-16 sm:w-0 lg:h-24 ${
          isFirstItem ? "" : "sm:block"
        }`}
      />
      <div
        className={`flex flex-col items-center text-white mx-auto w-44 pb-8 sm:pt-8 ${
          isFirstItem ? "" : "border-t border-white pt-8 sm:border-0"
        }`}
      >
        <div className="inline-flex pb-3 font-extrabold">
          <span className="number text-6xl lg:text-7xl">
            {parseInt(trafficNumber).toLocaleString("en-US")}
          </span>
          <span className="text-4xl font-bold lg:text-5xl">{unit}</span>
        </div>
        <p className="text-2xl text-white lg:text-3xl lg:leading-tight">
          {title}
        </p>
      </div>
    </div>
  )
}

const Result = ({ data }) => {
  const resultBlock = data.map((item, idx) => (
    <ResultCard key={idx} item={item} isFirstItem={idx === 0} />
  ))

  return (
    <div className="bg-amber-400">
      <div className="wrapper grid grid-cols-1 justify-center items-center py-16 sm:grid-cols-4 sm:gap-8 sm:h-72 lg:max-w-5xl lg:h-96">
        {resultBlock}
      </div>
    </div>
  )
}

ResultCard.propTypes = {
  item: PropTypes.object,
  isFirstItem: PropTypes.bool,
}
Result.propTypes = {
  data: PropTypes.array,
}

export default Result
