import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"
import { useLandingPageData } from "../data/useLandingPageData"
import CoursePlanning from "../components/CoursePlanning"
import Result from "../components/Result"
import Consult from "../components/Consult"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Logo from "../images/img_logo_vertical_gray.svg"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import Button from "../components/Button"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useMetadata } from "../data/use-metadata"
import { useModalUpdate } from "../contexts/ModalContext"

const About = ({ data }) => {
  const { coursePlanning, result, consultTitle, consultGatsbyImageData } =
    useLandingPageData()

  const setIsModal = useModalUpdate()

  const {
    slug,
    title,
    excerpt,
    image: {
      gatsbyImageData,
      file: { url },
    },
    content: { content },
    description: { description },
    serviceDescription: { serviceDescription },
  } = data.contentfulAboutPage

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/${slug}`

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: "關於我們",
      url: "/about",
    },
  ]
  return (
    <Layout>
      <GatsbySeo
        title="關於我們"
        description={description}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: "關於我們",
          description,
          images: [
            {
              url: `https:${url}`,
              width: 1200,
              height: 630,
              alt: "關於我們",
            },
          ],
        }}
      />

      <div className="my-12 wrapper">
        <Breadcrumb crumbs={crumbs} />
      </div>

      <div className="wrapper">
        <h1 className="text-3xl text-neutral-800 font-bold tracking-wide mb-8 sm:text-5xl sm:mb-12">
          關於我們
        </h1>
        <div className="lg:grid lg:grid-cols-3 lg:gap-16">
          <div className="border-l-4 border-l-amber-400 py-6 pl-5 lg:col-span-2">
            <h2 className="text-2xl mb-2 !leading-tight font-bold text-neutral-800 sm:text-4xl">
              {title}
            </h2>
            <p className="!leading-normal text-neutral-600 sm:text-lg">
              {description}
            </p>
          </div>
          <img
            src={Logo}
            alt="logo"
            className="hidden lg:block lg:col-span-1 lg:h-44 lg:mx-auto"
          />
        </div>
      </div>

      <div className="flex flex-col lg:grid lg:grid-cols-3 mt-8 sm:mt-12 lg:mt-24">
        <GatsbyImage
          image={gatsbyImageData}
          alt="bg image"
          className="block object-contain w-full h-80 lg:h-auto lg:col-span-1"
        />
        <div className="wrapper bg-neutral-50 py-8 px-6 sm:py-12 sm:px-16 lg:p-16 lg:col-span-2">
          <h2 className="text-2xl !leading-tight font-bold text-neutral-800 sm:text-4xl">
            {excerpt}
          </h2>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} className="markdown">
            {content}
          </ReactMarkdown>
          <div className="flex justify-center lg:justify-start">
            <Button size="md" isLink={false} onClick={setIsModal}>
              立即諮詢
            </Button>
          </div>
        </div>
      </div>

      <div className="wrapper mt-16 mb-8 sm:mt-24 sm:mb-12">
        <h2 className="mb-8 text-3xl !leading-normal tracking-wide font-bold text-neutral-800 sm:mb-12 sm:text-5xl">
          服務項目
        </h2>
        <p className="!leading-normal text-neutral-600 sm:text-lg">
          {serviceDescription}
        </p>
      </div>

      <CoursePlanning data={coursePlanning} showTitle={false} />
      <Result data={result} />

      <div className="wrapper my-24">
        <h2 className="mb-8 text-3xl !leading-normal tracking-wide font-bold text-neutral-800 sm:mb-12 sm:text-5xl">
          介紹影片
        </h2>
        <div
          className="relative h-0 overflow-hidden max-w-full w-full"
          style={{ paddingBottom: "56.25%" }}
        >
          <iframe
            className="absolute top-0 left-0 w-full h-full mb-24 lg:mb-36"
            src="https://player.vimeo.com/video/489285809"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>

      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

About.propTypes = {
  data: PropTypes.object,
}

export default About

export const pageQuery = graphql`
  {
    contentfulAboutPage(slug: { eq: "about" }) {
      title
      slug
      image {
        gatsbyImageData(
          width: 834
          height: 538
          placeholder: BLURRED
          quality: 100
        )
        file {
          url
        }
      }
      description {
        description
      }
      excerpt
      content {
        content
      }
      serviceDescription {
        serviceDescription
      }
    }
  }
`
