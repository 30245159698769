import React from "react"
import Button from "./Button"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { useModalUpdate } from "../contexts/ModalContext"

const Consult = ({ consultTitle, gatsbyImageData }) => {
  const setIsModal = useModalUpdate()

  return (
    <div className="bg-neutral-50">
      <div className="wrapper grid grid-cols-1 justify-items-center py-12 sm:py-16 lg:grid-cols-2 lg:gap-16 lg:justify-items-start">
        <GatsbyImage
          image={gatsbyImageData}
          alt="consult image"
          className="w-full sm:max-w-lg lg:order-2 lg:max-w-none"
        />
        <div className="flex flex-col justify-center items-center lg:order-1 lg:items-start">
          <h2 className="text-3xl leading-normal text-center font-bold text-neutral-800 mt-4 mb-8 sm:text-5xl sm:leading-normal lg:leading-tight lg:text-6xl lg:text-left lg:mb-12 lg:mt-0">
            {consultTitle}
          </h2>
          <Button isLink={false} onClick={setIsModal}>
            立即諮詢
          </Button>
        </div>
      </div>
    </div>
  )
}

Consult.propTypes = {
  consultTitle: PropTypes.string,
  gatsbyImageData: PropTypes.object,
}

export default Consult
