import React from "react"
import { ChevronRightIcon } from "@heroicons/react/solid"
import PropTypes from "prop-types"
import Link from "./Link"

const CoursePlanningCard = ({ title, slug, isFirstItem, isSecondItem }) => (
  <Link
    to={`/${slug}`}
    style={`flex justify-center items-center border border-neutral-500 rounded-lg h-16 ease-in-out duration-300 hover:bg-amber-400 hover:border-white group sm:h-28 lg:col-span-1 lg:h-32 ${
      isFirstItem
        ? "col-span-2 sm:col-span-3"
        : isSecondItem
        ? "sm:col-span-3"
        : "sm:col-span-2"
    }
`}
  >
    <div className="flex justify-center items-center flex-nowrap translate-x-3">
      <p className="text-lg font-bold text-neutral-800 ease-in-out duration-300 group-hover:text-white sm:text-2xl">
        {title}
      </p>
      <ChevronRightIcon className="h-8 text-amber-400 ml-1" />
    </div>
  </Link>
)

const CoursePlanning = ({ data, showTitle = true }) => {
  const coursePlanningBlock = data.map(({ title, slug }, index) => (
    <CoursePlanningCard
      title={title}
      slug={slug}
      key={index}
      isFirstItem={index === 0}
      isSecondItem={index === 1}
    />
  ))

  return (
    <div className="wrapper mb-16">
      <h2
        className={`text-3xl text-neutral-800 font-bold tracking-wide mb-8 mt-12 sm:text-5xl sm:mt-16 sm:mb-12 ${
          showTitle ? "" : "hidden"
        }`}
      >
        一站完成培訓規劃
      </h2>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6 sm:gap-8 sm:grid-cols-6 lg:grid-cols-5 lg:gap-4">
        {coursePlanningBlock}
      </div>
    </div>
  )
}
CoursePlanningCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  isFirstItem: PropTypes.bool,
  isSecondItem: PropTypes.bool,
}
CoursePlanning.propTypes = {
  data: PropTypes.array,
  showTitle: PropTypes.bool,
}

export default CoursePlanning
